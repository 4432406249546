var $ = jQuery.noConflict();

$(function () {
  /*** Script For SVG CODE  ***/
  jQuery("img.svg").each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    jQuery.get(
      imgURL,
      function (data) {
        /*** Get the SVG tag, ignore the rest ***/
        var $svg = jQuery(data).find("svg");

        /*** Add replaced image's ID to the new SVG ***/
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        /*** Add replaced image's classes to the new SVG ***/
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }
        /*** Remove any invalid XML tags as per http://validator.w3.org ***/
        $svg = $svg.removeAttr("xmlns:a");
        /*** Check if the viewport is set, else we gonna set it if we can. ***/
        if (
          !$svg.attr("viewBox") &&
          $svg.attr("height") &&
          $svg.attr("width")
        ) {
          $svg.attr(
            "viewBox",
            "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
          );
        }
        /*** Replace image with new SVG ***/
        $img.replaceWith($svg);
      },
      "xml"
    );
  }); 

  $(window).on('scroll', function() {
    if ($(this).scrollTop()) {
      $('#toTop').fadeIn();
    } else {
      $('#toTop').fadeOut();
    }
   });
   
   $("#toTop").on('click', function (e) {
   e.preventDefault();
     $("html, body").animate({scrollTop: 0}, 800);
   });

  /*** Header fixed ***/
  $(window).on('scroll', function() {
    var scrollPixel = $(window).scrollTop();
    if (scrollPixel < 56) {
      $("header").removeClass("header-fix");
    } else {
      $("header").addClass("header-fix");
    }
  });
    
  $("#menu-header-menu .menu-item-has-children .sub-menu").prepend(`<li class="sub-menu close-top-menu"><a href="#">Back</a></li>`);
  $("body .close-top-menu").on('click', function(e) {
    e.stopPropagation();
    $(this).parent().closest("li.menu-item-has-children").removeClass("sub-open");
  });
  $("li.menu-item-has-children").on('click', function(e) {
    $(this).addClass("sub-open");
    e.stopPropagation();
  });  

  $("body").on('click', function(e) {
    $(".navbar-collapse").removeClass("show");
    $("li.menu-item-has-children").removeClass("sub-open");
    $(".btn-menu-toggler").removeClass("toggler-active");
    $(".wl-navbar-collapse").removeClass("collapse-active");
    e.stopPropagation();
  });
  $(".btn-menu-toggler").on('click', function(e) {
    $(".btn-menu-toggler").toggleClass("toggler-active");
    $(".wl-navbar-collapse").toggleClass("collapse-active");
    e.stopPropagation();
  });

  var basePath = window.location.pathname;
  //  basePath = window.location.pathname.split('/')[1];
  $('.wl-collapse ul.sub-menu li > a[href="' + basePath + '"]').parent("li").addClass('current-menu-item'); 
  $('.wl-collapse>ul>li>a[href="' + basePath + '"]').parent("li").addClass('patent-active');

  $('.side-mail-icon').on('click', function() {
    $(this).parent('.siteform').toggleClass('active');
  });
  $('.closebtn').on('click', function() {
    $(this).closest('.siteform').removeClass('active');
  });
  
});  

   

